import React from 'react'
import SEO from '../components/seo'
import GameOfLife, { GameOfLifeProps } from '../components/GameOfLife'

export default function GameOfLifePage({ location }) {
  const params = new URLSearchParams(location.search)
  const props: GameOfLifeProps = {}
  // TODO value validation
  if (params.has('size')) props.size = Number(params.get('size'))
  if (params.has('speed')) props.speed = Number(params.get('speed'))
  if (params.has('survive'))
    props.survive = params.get('survive').split('').map(Number)
  if (params.has('spawn'))
    props.spawn = params.get('spawn').split('').map(Number)
  if (params.has('color')) props.color = params.get('color')
  if (params.has('shape')) props.shape = params.get('shape')
  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <SEO pathName={ location.pathname } />
      <GameOfLife {...props} />
    </div>
  )
}
